import HeroSection from "../sections/Hero";
import EducationSection from "../sections/Education";
import ExperienceSection from "../sections/Experience";
import ProjectsSection from "../sections/Projects";
import SkillsSection from "../sections/Skills";
import CTASection from "../sections/CTA";



const Home = () => {

  return (
    <>
      <HeroSection />
      <EducationSection/>
      <ExperienceSection/>
      <ProjectsSection/>
      <SkillsSection/>
      <CTASection/>
    </>
  );
};


export default Home;