//import HeroImg from "../assets/header-image-1.png";
import Resume from "../static/Resume.pdf";
import { HomeSection, MainContent, Lb, SubTitle, Title, Blurb, ResumeLink } from "./HeroComponents"
import { Spacer } from "../components/Section";


// const imageVariants = {
//     hidden: {
//         opacity: 0,
//         x: 80
//     },

//     visible: {
//         opacity: 1,
//         x: 0,
//         transition: { duration: 0.8, delay: 0.4, ease: "easeOut" }
//     }
// }

const item = {
    hidden: {
        opacity: 0,
        y: 80
    },

    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, delay: 0.4, ease: "easeOut" }
    }
}

const HeroSection = () => {

    return (
        <HomeSection id="home"
            variants={item}
            initial="hidden"
            animate="visible">
            <MainContent id="home">

                <Lb id="leftBlock">

                    <SubTitle 
                        variants={item}
                        initial="hidden"
                        animate="visible"
                    >
                        Hi, I'm
                    </SubTitle>
                    <Title 
                        variants={item}
                        initial="hidden"
                        animate="visible"
                    >
                        Kwamina
                    </Title>
                    
                    <Blurb 
                        variants={item}
                        initial="hidden"
                        animate="visible"
                    >
                        I love using my skills to build things and learning about new technologies. Currently exploring web development with NextJS.
                    </Blurb>

                    {/* <Topic 
                        variants={item}
                        initial="hidden"
                        animate="visible"
                    >
                        <StatusContainer>
                            <Circle />
                            <Status>
                                Seeking junior software engineering roles
                            </Status>
                        </StatusContainer>
                        
                    </Topic> */}

                    <ResumeLink target="_blank" rel="noreferrer" href={Resume}>
                        View Resumé
                    </ResumeLink>

                </Lb>

                {/* <HeroImage
                    initial="hidden"
                    animate="visible"
                    variants={imageVariants}
                    src={HeroImg}
                    alt="Header Image"
                    srcset=""
                    width="240"
                    height="240"
                /> */}

            </MainContent>

            {/* <ScrollIcon/> */}

            <Spacer/>


        </HomeSection>
    );
};



export default HeroSection;
