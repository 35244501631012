import {Section, Container, Title, Link} from "../components/Section";
import FadeIn from "../components/FadeWhenVisible";
import Flicker from "../components/Flicker";
import styled from "styled-components";

function CTA() {

    const CTATitle = styled(Title)`
        align-self: flex-start
    `;

    const Higlighted = styled.span`
        font-size: 32px;
        font-weight: bold;
        color: var(--green);
        padding-left: 8px;
    `;

    const Body = styled(Title)`
        margin-left: 2rem;
        color: ${props => props.color || "var(--white)"};

        &:hover {
            color: white;
        }
    `;

    const CTALink = styled(Link)`
        width: auto;
        font-size: 32px;
        color: var(--offwhite);
        text-decoration: underline dotted #707078;
    `;

    return (
        <Section id="cta">
            <FadeIn>

                {/* <Flicker>

                    <Container>
                        
                        <CTATitle>
                            if <Higlighted> recruiter </Higlighted>:
                        </CTATitle>

                        <Body>
                            seeking junior software engineering roles
                        </Body>

                        <CTALink 
                            onClick={(e) => {
                                window.location = "mailto:theernestmensah@gmail.com";
                                e.preventDefault();
                            }}
                        >
                            
                            <Body color={"var(--offwhite)"}>
                                message me
                            </Body>
                        </CTALink>
                        
                    </Container>
                        
                </Flicker> */}
                


                <Flicker>

                    <Container>
                        <Flicker>
                            <CTATitle>
                                if <Higlighted> software_enthusiast </Higlighted>:
                            </CTATitle>
                        </Flicker>
                        

                        <Body>
                            let's connect on LinkedIn or
                        </Body>

                        <CTALink 
                            onClick={(e) => {
                                window.location = "mailto:theernestmensah@gmail.com";
                                e.preventDefault();
                            }}
                        >
                            
                            <Body color={"var(--offwhite)"}>
                                message me
                            </Body>
                        </CTALink>
                    </Container>
                </Flicker>
                
            </FadeIn>
        </Section>
    )
}

export default CTA
