import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion"
import "./GlobalStyle.css"
import { Link } from "../components/Section";

export const move = keyframes`
0% { transform: translateY(-8px)  }
    50% { transform: translateY(16px) }
    100% { transform: translateY(-8px) }
`;

export const scale = keyframes`
0% { transform: scale(1)  }
    50% { transform: scale(0.8) }
    100% { transform: scale(1) }
`;


export const HomeSection = styled(motion.section)`

  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  
  // @media only Screen and (max-width: 48em) {
  //   margin: 0;
  //   height: auto;
  //   //background-color: purple;
  // }

  // @media only Screen and (max-width: 420px) {
  //   height: auto;
  //   padding-bottom: 2rem;
  //   //background-color: blue;
  // }
`;


export const Spacer = styled(motion.div)`
  display: flex;
  height: 10rem;
`;


export const MainContent = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 80vw;

  @media only Screen and (max-width: 48em) {
    flex-direction: column-reverse;
  }

  @media only Screen and (min-width: 1024px) {
    height: 40vw;
  }
`;

export const HeroImage = styled(motion.img)`

  width: calc(30% + 10vw);
  height: auto;
  align-self: center; 
  //animation: ${move} 15s ease-in-out infinite;

  @media only Screen and (min-width: 100em) {
    width: calc(25% + 10vw);
  }

  @media only Screen and (max-width: 48em) {
    width: calc(25% + 25vw);
  }

`;

export const Lb = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  line-height: 1.5;
  color: var(--white);
  position: relative;
  //background: orange;
  z-index: 15;
  @media only Screen and (max-width: 48em) {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: space-between;
  }

`;


export const SubTitle = styled(motion.h2)`
  font-size: 24px;
  line-height: 0.5;
  padding: 0.5rem 0;
  //margin-top: 3rem;
  margin-right: 3rem;
  font-weight: lighter;

  @media only Screen and (max-width: 30em) {
    margin: 0;
    font-size: 1.5rem;
  }

`;


export const Title = styled(motion.h1)`
  font-size: 80px;
  font-weight: lighter;
  line-height: 1.2;
  padding: 0.5rem 0;
  color: var(--green);
  margin-right: 3rem;

  @media only Screen and (max-width: 30em) {
    // position: absolute;
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 3rem;
    font-size: 3rem;
    padding: 0;
  }
  
`;

export const Blurb = styled(motion.p)`
  font-size: 1rem;
  color: var(--offwhite);
  padding-top: 1rem;
  width: 90%;

  @media only Screen and (max-width: 30em) {
    padding-top: 2rem;
    margin: 0rem;
    width: auto;
  }
`;

export const Topic = styled(motion.span)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--nav);
  color: var(--white);
  font-weight: 700;
  font-size: calc(0.4rem + 0.4vw);
  margin-top: 32px;
  margin-bottom: 16px;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  //height: 72px;
`;


export const StatusContainer = styled.div`
  display: flex;
  //background-color: blue;
`;

export const ResumeLink = styled(Link)`
  font-size: 1rem;
  font-weight: bold;
  color: var(--green);
  margin-top: 1rem;

  font-weight: 50;
  line-height: 1.5;
  &::after {
    content: "";
    display: block;
    width: 0;
    background: transparent;
    transition: width 0.5s;
  }
  &:hover::after {
    width: 80%;
    height: 1px;
    background: var(--green);
  }
`;

export const Circle = styled.span`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--success);
  margin-right: 1rem;
//   animation: ${scale} 7s ease-in-out infinite;
`;


export const Status = styled.span`
  font-size: 0.8rem;
  color: var(--white);
`