import SmoothScroll from "./components/SmoothScroll"
import Home from "./pages/Home";
import Footer from "./components/Footer";
import ScrollButton from './components/ScrollButton';

function App() {

  return (
   
    <SmoothScroll>
      <Home/>
      <ScrollButton/>
      <Footer/>
    </SmoothScroll>
  
  );
}

export default App;
