import React from 'react';
import styled from "styled-components";
import { Section, Spacer, Container, Header, Title, Button, SubText, Body, Link } from "../components/Section";
import WigclubHeader from "../assets/wigclub/wigclub-header.png";
import WigclubShowcase from "../assets/wigclub/showcase.png";
import UMDSchedulrHeader from "../assets/umd-schedulr/umd-schedulr.png";
import UMDShowcase from "../assets/umd-schedulr/showcase.png";
import Sketchpad from "../assets/sketchpad/sketchpad-1.gif";
import FadeIn from "../components/FadeWhenVisible";


const TechStack = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
`;

const InfoHeader = styled(SubText)`
    margin-top: 8px;
    margin-right: 8px;
`;

const Text = styled.p`
    display: flex;
    color: var(--offwhite);
`;

const ProjectsContainer = styled(Container)`
    //background-color: blue;
    flex-direction: column;
    justify-content: flex-start;
    height: ${props => props.height || "auto"};

    // Mobile resolutions
    @media only Screen and (max-width: 40em) {
        flex-direction: column;
    }
`;


const SummaryContainer = styled.div`
    display: flex;
    //background-color: yellow;
    margin-bottom: 2rem;
    flex-direction: ${props => props.direction};

    @media only Screen and (max-width: 40em) {
        flex-direction: column;
    }
`;

const InfoContainer = styled(Container)`
    flex-direction: column;
    width: 100%;
    align-self: flex-start;
    //background-color: red;

    @media only Screen and (max-width: 40em) {
        flex-direction: column;
        width: 100%;
    }
`;

const DetailContainer = styled.div`
    display: flex;
    flex-direction: row;
    //background-color: purple;
    // align-items: center;
    justify-content: space-between;
    height: auto;
    width: auto;

    @media only Screen and (max-width: 40em) {
        flex-direction: column;
    }
`;

const DetailTitle = styled(Title)`
    //background-color: green;

    margin-top: 2rem;
    width: 20%;

    @media only Screen and (max-width: 40em) {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-self: center;
        padding-left: 1.25rem;
        //text-align: left;
        //background-color: green;
    }
`;

const BodyContainer = styled.div`
    //background-color: teal;
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media only Screen and (max-width: 40em) {
        width: 100vw;
        align-self: center;
        // padding-left: 1rem;
        // padding-right: 1rem;
    }
`;


const ProjectBody = styled(Body)`
    width: 50%;
    //background-color: gray;
    align-self: flex-end;

    @media only Screen and (max-width: 40em) {
        width: 100vw;
        //align-self: center;
        padding: 1.5rem;
    }
`;

const ShowcaseImage = styled.img`
    width: auto;
    height: auto;

    @media only Screen and (max-width: 40em) {
        width: 300%;
        height: 100%;
    }

    @media only Screen and (min-width: 1140px) {
        width: 130%;
        height: 100%;
      }
    
    @media only Screen and (min-width: 2800px) {
        width: 100%;
        height: 100%;
    }
`;


const ProjectImage = styled.img`

  width: 640px;
  height: 480px;
  align-self: center;
  //background-color: blue;
  padding-right: 2.5rem;
  border-radius: 16px;

  @media only Screen and (min-width: 1140px) {
    width: 100%;
    height: 100%;
  }

  @media only Screen and (min-width: 2800px) {
    width: 100%;
    height: 100%;
  }
`;


const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 16px;
  //background-color: green;

`;

const ViewButton = styled(Button)`
  align-self: ${props => props.align || "flex-end"};
  margin: 2rem;

  // Mobile resolutions
  @media only Screen and (max-width: 48em) {
    margin-left: 0rem;
    margin-top: 1rem;
    //margin: 0;
  }
`;


export default function Projects() {

    
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    
    return (
        <Section id="projects">
            <FadeIn>
                <Header>
                    PROJECTS
                </Header>
            </FadeIn>

            <FadeIn>
                <ProjectsContainer>

                    <SummaryContainer direction="row" height="100vw">
                        
                        <InfoContainer>
                            <Title>
                                1. WIGCLUB
                            </Title>

                            <Body>
                                I designed, built and published an iOS app for my sister's business that deals in the sale of hair and beauty products.
                            </Body>

                            <TechStack>
                                <InfoHeader>
                                    Technologies used:
                                </InfoHeader>

                                <Text>
                                    Swift, Firebase, Node.js
                                </Text>
                            </TechStack>

                        </InfoContainer>

                        <ImageContainer>

                            <ProjectImage
                                src={WigclubHeader}
                                alt="Wigclub app image"
                                srcset=""
                            />

                        </ImageContainer>

                    </SummaryContainer>

                    <ShowcaseImage
                        src={WigclubShowcase}
                        alt="Wigclub app showcase image"
                    />
                    

                    
                    <DetailContainer>
                        <DetailTitle> BEHIND THE SCENES </DetailTitle>

                        <BodyContainer>

                            <ProjectBody>
                                I wanted the design of the app to be simple, providing the least amount of friction between the 
                                products and the customer so I took inspiration from design sites like Pinterest and Dribbble.
                            </ProjectBody>

                            <ProjectBody>
                                I decided to go native, writing the front-end of the app in Swift because I didn't want to rely on third-party frameworks.
                                UIKit and CoreData were the frameworks I used for this project.
                            </ProjectBody>

                            <ProjectBody>
                                For the back-end, I decided to leverage Firebase from Google. I used their authentication service to handle all things related to users 
                                of the app (authentication and sign-in), their realtime database to store all data generated, their cloud storage to store user-generated content and their cloud-functions service to run Node.js scripts to handle server-side logic.
                            </ProjectBody>

                        </BodyContainer>
                        
                    </DetailContainer>

                    <DetailContainer>
                        <DetailTitle> WHAT I LEARNED </DetailTitle>

                        <BodyContainer>

                            <ProjectBody>
                                Adopting the agile methodology proved indispensible during the initial stages of development. By involving my sister in important design decisions,
                                I was able to implement features that she believed would serve her customers best.
                            </ProjectBody>

                            <ProjectBody>
                                I also learned how important taking time to design a good architecture for a system can save a lot of time and trouble in the long run. 
                                I found myself violating the DRY (Don't Repeat Yourself) principle during early stages of implementation because of my poor initial design.
                            </ProjectBody>

                        </BodyContainer>
                    </DetailContainer>

                    <ViewButton align="flex-start" onClick={() => openInNewTab('https://apps.apple.com/us/app/wigclub/id1439212987')}>
                        View on the AppStore →
                    </ViewButton>

                </ProjectsContainer>
            </FadeIn>

            <FadeIn>
                <ProjectsContainer >

                    <SummaryContainer direction="row" height="100vw">
                            
                            <InfoContainer>
                                <Title>
                                    2. UMD SCHEDULR
                                </Title>

                                <Body>
                                    I designed, built and published an iOS app for managing my class and personal schedule when
                                    I was an undergraduate.
                                </Body>

                                <TechStack>
                                    <InfoHeader>
                                        Technologies used:
                                    </InfoHeader>

                                    <Text>
                                        Swift, REST
                                    </Text>
                                </TechStack>

                            </InfoContainer>

                            <ImageContainer>

                                <ProjectImage
                                    src={UMDSchedulrHeader}
                                    alt="UMD Schedulr app image"
                                    srcset=""
                                />

                            </ImageContainer>

                        </SummaryContainer>

                        <ShowcaseImage
                            src={UMDShowcase}
                            alt="UMD Schedulr app showcase image"
                        />


                        <DetailContainer>
                            <DetailTitle> IMPLEMENTATION AND TAKE-AWAYS </DetailTitle>

                            <BodyContainer>

                                <ProjectBody>
                                    This app was built entirely with Swift with a heavy lean on <strong>CoreData</strong> to
                                    save events locally on device. I utilized an open source library called <Link target="_blank" rel="noreferrer" 
                                    href="https://github.com/richardtop/CalendarKit">CalendarKit</Link> by Richard Topchii to handle displaying saved events.
                                </ProjectBody>

                                <ProjectBody>
                                    Whenever a third-party framework is brought into your codebase, you introduce the chance of
                                    your code breaking when the author
                                    of that framework makes any changes. I learned this the hard way when an update to
                                    CalendarKit caused my application to crash.
                                    I was eventually able to resolve the issue but I learned from that experience to write
                                    wrappers around any third-party libraries
                                    so I can control how my code utilizes them.
                                </ProjectBody>

                            </BodyContainer>
                            
                        </DetailContainer>

                        <ViewButton align="flex-start" onClick={() => openInNewTab('https://apps.apple.com/app/id1473512625')}>
                            View on the AppStore →
                        </ViewButton>

                </ProjectsContainer>
            </FadeIn>

            <FadeIn>
                <ProjectsContainer direction="column">

                    <InfoContainer>
                        <Title>
                          3. SKETCH PAD
                        </Title>

                        <Body>
                            I designed and built a sketch pad application that allows users to record, save and playback their drawings (Note: Only works on desktop browsers)
                        </Body>

                        <TechStack>
                            <InfoHeader>
                                Technologies used:
                            </InfoHeader>

                            <Text>
                                HTML5, CSS, Javascript
                            </Text>
                        </TechStack>

                    </InfoContainer>

                    <ImageContainer>

                        <ProjectImage
                            src={Sketchpad}
                            alt="Sketchpad application"
                            srcset=""
                        />

                    </ImageContainer>


                    <ViewButton align="flex-start" onClick={() => openInNewTab('https://sketch-pad-v1.netlify.app')}>
                        Try it out →
                    </ViewButton>

                </ProjectsContainer>
                
            </FadeIn>

            <FadeIn>
                <ProjectsContainer direction="column">

                    <SummaryContainer direction="row" height="100vw">

                    </SummaryContainer>

                    <InfoContainer>
                        <Title>
                          4. MY LIBRARY WEB APP
                        </Title>

                        <Body>
                            I designed and built a full-stack web application that allowed me to catalog books I've read and enjoyed. Leveraged data from Google's Books API.
                        </Body>

                        <TechStack>
                            <InfoHeader>
                                Technologies used:
                            </InfoHeader>

                            <Text>
                                React.js (Front-end) Node.js, Express, PostgreSQL (Back-end)
                            </Text>
                        </TechStack>

                    </InfoContainer>

                    <DetailContainer>
                            <DetailTitle> IMPLEMENTATION AND TAKE-AWAYS </DetailTitle>

                            <BodyContainer>

                                <ProjectBody>
                                    I have always been excited by web development. After reading up on Javascript and it's immense capabilities, I wanted 
                                    to build something to practice my skills. Coincidentally, I had just completed reading a couple of books on the art of 
                                    software development so the idea to build a library of books I've enjoyed was a no-brainer. 
                                </ProjectBody>

                                <ProjectBody>
                                    I got to experience how state is managed with React when I had to consume data from Google's Books API and 
                                    present them on screen. Similarly, building the back-end with Express.js and PostgreSQL allowed me to practice building 
                                    a RESTful API. 
                                </ProjectBody>

                            </BodyContainer>
                            
                    </DetailContainer>

                    <ViewButton align="flex-start" onClick={() => openInNewTab('https://github.com/kwam1na/books-app')}>
                        View on Github →
                    </ViewButton>

                </ProjectsContainer>
                
            </FadeIn>

            <FadeIn>
                <ProjectsContainer direction="column">

                    <SummaryContainer direction="row" height="100vw">

                    </SummaryContainer>

                    <InfoContainer>
                        <Title>
                          5. BANK SYSTEM
                        </Title>

                        <Body>
                            I built a thread-safe command-line application that simulates a bank. Users can be created and actions such as funds withdrawal, deposits and transfers are supported.
                            Followed industry practices to ensure multithreaded operations occur with no side effects.
                        </Body>

                        <TechStack>
                            <InfoHeader>
                                Technologies used:
                            </InfoHeader>

                            <Text>
                                Java
                            </Text>
                        </TechStack>

                    </InfoContainer>

                    <DetailContainer>
                            <DetailTitle> WHAT I LEARNED </DetailTitle>

                            <BodyContainer>

                                <ProjectBody>
                                    Single-threadded programs, although relatively easy to write don't fully utilize the power of today's computers. I have been reading on multi-threading and wanted
                                    to explore firsthand the benefits of its application. Java implements multithreadding in multiple ways. From object locks to semaphores to special keywords for method 
                                    signatures. I chose the latter in my implementaion. By including the keyword <em>synchronized,</em> sensitive methods can be marked to allow only one thread to operate on 
                                    an object at any given time. 
                                </ProjectBody>

                                <ProjectBody>
                                    Architecturally, I abstracted the Persistence layer of my system. By creating a Database interface that has CRUD operations that any class that implements it defines, I have 
                                    the flexibility to define a LocalDB class that uses a data structure to store information. Similarly, should I need to have true persistence in the future, I can easily create 
                                    a class that implements the Database interface and also connects to any of the data store providers.
                                </ProjectBody>

                            </BodyContainer>
                            
                    </DetailContainer>

                    <ViewButton align="flex-start" onClick={() => openInNewTab('https://github.com/kwam1na/bank-app')}>
                        View on Github →
                    </ViewButton>

                </ProjectsContainer>
                
            </FadeIn>

            <Spacer/>

        </Section>
    )
}
