import { Section, Spacer, Container, Header, Title, SubTitle, SubText, Body } from "../components/Section"
import FadeIn from "../components/FadeWhenVisible"
import Flicker from "../components/Flicker"

const Education = () => {

    return (

        <Section id="experience">
            <FadeIn>
                <Header>
                    EDUCATION
                </Header>
            </FadeIn>

            <FadeIn>
                <Container>
                    <Flicker>
                        <Title>
                            University of Maryland, College Park
                        </Title>
                    </Flicker>
                    

                    <SubTitle>
                        BSc. Computer Science
                    </SubTitle>

                    <SubText>
                        Aug 2018 - May 2021
                    </SubText>

                    <Body>
                        <strong>Relevant coursework: </strong> Advanced Data Structures, Algorithms, Artificial Intelligence,
                        C Programming, Computer Systems Architecture, Computer and Network Security, Organization of Programming Languages, Programming Handheld Systems - iOS
                    </Body>

                </Container>
            </FadeIn>


            <FadeIn>
                <Container>
                    
                    <Flicker>
                        <Title>
                            Community College of Baltimore County
                        </Title>
                    </Flicker>

                    <SubTitle>
                        ASc. Computer Science
                    </SubTitle>

                    <SubText>
                        Aug 2015 - May 2018
                    </SubText>

                    <Body>
                        <strong>Activities and societies: </strong> Dean's list (Spring '16 and '17 semesters), Math and Computer Inspired Scholars (MCIS), Phi Theta Kappa
                    </Body>

                    <Body>
                        Completed the Mobile Application Developer certifcation course track.
                    </Body>
                </Container>

                <Spacer/>
                
            </FadeIn>

        </Section>
    )
};

export default Education;