import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import styled from "styled-components";

const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    //background-color: red;
    width: auto;
    height: auto;
`

function FadeInWhenVisible({ children }) {
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      } else {
        controls.start("hidden")
      }
    }, [controls, inView]);
  
    return (
      <Container
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.5 }}
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0, scale: 1 }
        }}
      >
        {children}
      </Container>
    );
  }


  export default FadeInWhenVisible;