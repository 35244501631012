import React from 'react';
import styled from "styled-components";
import { FaGithub, FaLinkedin} from "react-icons/fa";
import { Link } from "./Section"

const FooterContainer = styled.div`
    background-color: var(--background);
    height: 120px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: space-between;
`;

const List = styled.u`
    display: flex;
    width: 180px;
    padding: 1rem;
    justify-content: space-around;
`;


const Copyright = styled.h3`
    font-size: 16px;
    color: white;
`;


const FooterLink = styled(Link)`
    &:hover {
        color: white;
    }
`;


export default function Footer() {
    
    return (
        <FooterContainer>
            <LinksContainer>
                <List>
                    <FooterLink target="__blank" href="https://github.com/kwam1na" rel="noreferrer">
                        <FaGithub size={28} /> 
                    </FooterLink>

                    <FooterLink target="__blank" href="https://linkedin.com/in/ernestmens" rel="noreferrer">
                        <FaLinkedin size={28}/> 
                    </FooterLink>
                </List>
            </LinksContainer>
            
            <Copyright>
                &copy; 2022 Kwamina Essuah Mensah
            </Copyright>
        </FooterContainer>
    )
}
