import React from 'react';
import styled from "styled-components";
import { Section, Container, Header, Title } from "../components/Section";
import FadeIn from "../components/FadeWhenVisible";
import CodingIcon from "../components/CodingIcon"
import MusicIcon from "../components/MusicIcon"



const SIContainer = styled(Container)`
    //background-color: green;
    margin: 0;
    flex-direction: ${props => props.alternate ? "row-reverse" : "row"};

    // Mobile resolutions
    @media only Screen and (max-width: 40em) {
        width: 100%;
        flex-direction: column;
    }
`;


const SITitle = styled(Title)`
    align-self: flex-start;
    padding-bottom: 2rem;
    padding-left: 0rem;
`;


const ListContainer = styled.ul`
    display: flex;
    flex-direction: column;
    //background-color: purple;
    justify-content: flex-start;
    color: var(--white);
    list-style-type: none;
    
    width: 40%;
    margin: 0rem 5rem;
    

    // Mobile resolutions
    @media only Screen and (max-width: 40em) {
        margin-left: 0;
        width: 100%;
    }
`;


const Skills = () => {

    return (
        <Section id="skills">

            <FadeIn>
                <Header>
                    SKILLS &amp; INTERESTS
                </Header>
            </FadeIn>

            <FadeIn>
                <Container>
                    <SIContainer>
                        <ListContainer>
                            <SITitle>
                                LANGUAGES AND TOOLS
                            </SITitle>

                            <li>Swift / SwiftUI</li>
                            <li>Java</li>
                            <li>C</li>
                            <li>Python</li>
                            <li>Javascript (React.js, Node.js)</li>
                            <li>Bootstrap</li>
                            <li>HTML5 / CSS</li>
                            <li>SQL (PostgreSQL, MySQL, NoSQL)</li>
                            <li>REST</li>
                            <li>Karate</li>
                            <li>Git</li>
                            <li>Terminal</li>
                            <li>iWork Suite</li>
                            <li>MS Office Suite</li>
                            <li>Photoshop</li>
                        </ListContainer>

                        <CodingIcon/>

                    </SIContainer>

                    <SIContainer alternate>

                        <ListContainer>

                            <SITitle>
                                INTERESTS
                            </SITitle>
                            <li>Working out</li>
                            <li>Outdoors (Hiking, Camping, River tubing)</li>
                            <li>Concerts</li>
                            <li>Movies (Comic book adaptations, Thrillers, Horror, SciFi)</li>
                            <li>Reading (Particular books on Software development and Life)</li>
                            <li>Music</li>
                            <li>Travel</li>
                            <li>Soccer</li>
                        </ListContainer>

                        <MusicIcon/>

                    </SIContainer>
                </Container>
            </FadeIn>
            
        </Section >
    )
}


export default Skills;