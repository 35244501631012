import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

export const flicker = keyframes`
    0%   { opacity:0; }
    25%  { opacity:0.5; }
    45%  { opacity:0.7; }
    50%  { opacity:0; }
    60%  { opacity:0; }
    90%  { opacity:0.7; }
    100% { opacity:1; }
`;

export const Spacer = styled(motion.div)`
  display: flex;
  height: 10rem;

  @media only Screen and (max-width: 40em) {
    height: 6rem;
    //background-color: teal;
  }

  @media only Screen and (min-width: 100em) {
    height: 12.5rem;
  }
`;

export const Section = styled(motion.section)`
    width: 100vw;
    height: auto;
    //background-color: var(--background);
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 2.5rem;
    //background-color: teal;
`;

export const Header = styled(motion.h1)`
    display: flex;
    color: var(--white);
    //background-color: red;
    margin-bottom: 64px;
    font-size: 6.5rem;
    font-weight: lighter;
    align-self: center;

    // Mobile resolutions
    @media only Screen and (max-width: 40em) {
        font-size: 3rem;
        padding-left: 2rem;
    }
`;

export const ToolTip = styled.h3`
    color: var(--white);
    text-align: center;
    font-size: 1rem;
`;

export const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    //background-color: red;
    width: 85vw;
    height: auto;
    padding-bottom: 5rem;
`

export const Title = styled(motion.h2)`
    display: flex;
    color: var(--white);
    font-size: 32px;
    font-weight: lighter;
    //opacity: 0;
    //background-color: purple;
    padding-left: 2rem;

    @media only Screen and (max-width: 40em) {
        padding: 0rem;
    }
`;

export const SubTitle = styled(motion.h3)`
    display: flex;
    color: var(--offwhite);
    font-size: 21px;
    font-weight: lighter;
    //background-color: orange;
    padding-left: 2rem;

    @media only Screen and (max-width: 40em) {
        padding: 0rem;
    }
`;

export const SubText = styled(motion.h3)`
    display: flex;
    color: var(--green);
    font-size: 16px;
    //background-color: orange;
    padding-left: 2rem;

    @media only Screen and (max-width: 40em) {
        padding: 0rem;
    }
`;

export const Body = styled(motion.p)`
    display: inline;
    color: var(--lavendar);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    //background-color: yellow;

    @media only Screen and (max-width: 40em) {
        padding-left: 0rem;
        padding-right: 0rem;
        padding-top: 1.5rem;
    }
`

export const Link = styled.a`
    text-decoration: none;
    outline: none;
    color: var(--offwhite);
    cursor: pointer;
`


export const Button = styled.button`
    // background-color: var(--background);
    // font-size: 14px;
    // width: 120px;
    // height: 40px;
    // color: var(--white);
    // border: 1px solid var(--offwhite);
    // border-radius: 4px;
    // cursor: pointer;

    border-radius: 4px;
	white-space: nowrap;
	padding: 10px 20px;
	font-size: 14px;
	color: #fff;
	outline: none;
	border: 1px solid var(--offwhite);
	cursor: pointer;
	overflow: hidden;
    display: block;
    background: linear-gradient(to right, var(--white) 50%, var(--background) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .4s ease-out;

    &:hover {
        background-position: left bottom;
        color: black;
    }
`;