import { motion } from "framer-motion";
import styled from "styled-components";

export default function CodingIcon() {

    const IconContainer = styled(motion.div)`
        width: 300px;
        height: 300px;
        //background-color: pink;

        @media only Screen and (max-width: 40em) {
            //display: none;
            align-self: center;
            width: 180px;
            height: 180px;
        }
    `;

    const transition = {
        duration: 8,
        ease: "anticipate",
        repeat: Infinity,
        repeatDelay: 1
    };

    

    return (
        
        <IconContainer>
            <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 82.5" x="0px" y="0px">
                <motion.path
                    d="M11.71,23.29a1,1,0,0,0-1.42,1.42L13.59,28l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,0-1.42Z"
                    fill="none"
                    stroke="#707078"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                />
                <motion.path
                    d="M25,31H19a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z"
                    fill="none"
                    stroke="#707078"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                />
                <motion.path
                    d="M60,4H6A5,5,0,0,0,1,9V57a5,5,0,0,0,5,5H60a5,5,0,0,0,5-5V9A5,5,0,0,0,60,4Zm3,53a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V9A3,3,0,0,1,6,6H60a3,3,0,0,1,3,3Z"
                    fill="none"
                    stroke="#707078"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                />
                <motion.path
                    d="M58,14H8a1,1,0,0,0,0,2H58a1,1,0,0,0,0-2Z"
                    fill="none"
                    stroke="#707078"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                />
                <motion.path 
                    fill="none"
                    stroke="#707078"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M9,11h2a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                />
                <motion.path 
                    d="M15,11h2a1,1,0,0,0,0-2H15a1,1,0,0,0,0,2Z"
                    fill="none"
                    stroke="#707078"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                />
                <motion.path 
                    d="M55,11h2a1,1,0,0,0,0-2H55a1,1,0,0,0,0,2Z"
                    fill="none"
                    stroke="#707078"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                />
            </motion.svg>
        </IconContainer>
    )
}
