import styled from "styled-components";
import { motion } from "framer-motion";

export default function MusicIcon() {

    const IconContainer = styled(motion.div)`
        width: 300px;
        height: 300px;
        //background-color: pink;

        @media only Screen and (max-width: 40em) {
            width: 180px;
            height: 180px;
            align-self: center;
        }
    `;

    const transition = {
        duration: 8,
        ease: "anticipate",
        repeat: Infinity,
        repeatDelay: 1
    };

    return (
        <IconContainer>
            <motion.svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 106" viewBox="0 0 108 135" x="0px" y="0px">
                <motion.path
                    fill="none"
                    stroke="#707078"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                    d="M89.08,62.19a20.37,20.37,0,0,0-13.8-.62c-10,3-16,12.5-13.34,21.2a15.44,15.44,0,0,0,9,9.75A19.55,19.55,0,0,0,78.58,94a21.53,21.53,0,0,0,6.16-.91c8.61-2.58,14.24-10,13.93-17.62,0-.1,0-.2,0-.3V9c0-.14,0-.27,0-.41s0-.26,0-.4l0,0a3.51,3.51,0,0,0-.15-.53,3.18,3.18,0,0,0-.1-.33c-.06-.15-.15-.28-.22-.42s-.12-.25-.2-.36a3.65,3.65,0,0,0-.24-.31,3.47,3.47,0,0,0-.31-.36,3.33,3.33,0,0,0-.28-.25c-.13-.1-.24-.21-.38-.31a3.47,3.47,0,0,0-.33-.18A3.55,3.55,0,0,0,96,4.87a3,3,0,0,0-.32-.11,4.48,4.48,0,0,0-.52-.16l-.3,0c-.19,0-.39,0-.59-.06h0c-.13,0-.26,0-.39,0s-.28,0-.42,0L41.37,14h0a4.1,4.1,0,0,0-.53.16l-.34.1a4.23,4.23,0,0,0-.42.22c-.12.07-.24.12-.35.19s-.22.18-.33.27a3.17,3.17,0,0,0-.34.28c-.11.1-.19.22-.29.33a2.69,2.69,0,0,0-.26.33,4.1,4.1,0,0,0-.23.4c-.06.12-.14.23-.19.36a2.55,2.55,0,0,0-.13.39,3.36,3.36,0,0,0-.14.45,2.09,2.09,0,0,0,0,.35,3.31,3.31,0,0,0-.06.54V72c-.21-.1-.41-.21-.62-.3A20.37,20.37,0,0,0,23.26,71c-10,3-16,12.5-13.34,21.2a15.4,15.4,0,0,0,9,9.74,19.38,19.38,0,0,0,7.64,1.53,21.53,21.53,0,0,0,6.16-.91C41.33,100,47,92.56,46.65,85c0-.1,0-.2,0-.3V45.86L89.7,38V62.49C89.49,62.39,89.29,62.28,89.08,62.19ZM82.16,84.51a11.37,11.37,0,0,1-7.68-.27,6.56,6.56,0,0,1-3.92-4.06c-1.16-3.88,2.18-8.45,7.3-10a12.49,12.49,0,0,1,3.59-.53,10.5,10.5,0,0,1,4.09.8,6.61,6.61,0,0,1,3.92,4.06C90.62,78.4,87.28,83,82.16,84.51ZM30.14,94a11.37,11.37,0,0,1-7.68-.27,6.61,6.61,0,0,1-3.92-4.06c-1.16-3.88,2.18-8.45,7.3-10a12.49,12.49,0,0,1,3.58-.54,10.38,10.38,0,0,1,4.1.81A6.61,6.61,0,0,1,37.44,84C38.6,87.86,35.26,92.43,30.14,94ZM46.68,36.71V22.21l43-7.82v14.5Z"/>
            </motion.svg>
        </IconContainer>
    );
};