import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import styled from "styled-components";

const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    //align-self: center;
    //justify-content: center;
    //background-color: red;
    width: auto;
    height: auto;
`

function Flicker({ children }) {
    const controls = useAnimation();
    //const [animated, setAnimated] = useState(false);
    const [ref, inView] = useInView();


    useEffect(() => {
        if (inView) {
            controls.start("visible");
            //setAnimated(playOnce);
        } else {
            controls.start("hidden");
        }

    }, [controls, inView]);

    return (
        <Container
            ref={ref}
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.9, delay: 1.8 }}
            variants={{
                visible: { opacity: [0, 0.5, 0, 7, 0, 0, 0.7, 1] },
                hidden: { opacity: 0 }
            }}
        >
            {children}
        </Container>
    );
}


export default Flicker;