import {Section, Spacer, Container, Header, Title, SubTitle, SubText, Body, Link} from "../components/Section"
import FadeIn from "../components/FadeWhenVisible"
import Flicker from "../components/Flicker"


const Experience = () => {


    return (

        <Section id="experience">
            <FadeIn>
                <Header>
                    WORK EXPERIENCE
                </Header>
            </FadeIn>

            <FadeIn>
                <Container>

                    <Flicker>
                        <Title>
                            Eventbrite
                        </Title>
                    </Flicker>
                    

                    <SubTitle>
                        Software Engineer
                    </SubTitle>

                    <SubText>
                        Mar 2022 - Present
                    </SubText>

                    <Body>
                        After interviewing with a couple of companies in the Winter of 2022, I joined Eventbite as a software engineer.
                        I am on the Marketing Automation team building amazing tools to help creators grow their audience.
                    </Body>

                    

                </Container>
            </FadeIn>



            <FadeIn>
                <Container>

                    <Flicker>
                        <Title>
                            Apple
                        </Title>
                    </Flicker>
                    

                    <SubTitle>
                        Genius
                    </SubTitle>

                    <SubText>
                        Oct 2017 - Present
                    </SubText>

                    <Body>
                        During my undergraduate years, I started working at Apple Columbia as a Specialist. During that time, I built strong interpersonal and customer-focused skills which translated to
                        stellar reviews from management YoY.
                    </Body>

                    <Body>
                        After two years, I was promoted to the Genius Bar as a Technical Expert where I built my troubleshooting and analytical skills. As a Technical Expert, I mentored new and
                        existing teammates by providing expertise knowledge on best practices. I also performed modular repairs on iPhones and was among the top performing technicians with a same unit repair rate
                        that was consistently above 95%.
                    </Body>

                    <Body>
                        In June of 2021, I was promoted to the role of Genius where in addition to the responsibilities of a Technical Expert, I perform modular repairs on Mac computers. This role allows me to
                        get hands-on experience troubleshooting issues and repairing relationships with users and their devices while serving as a resource to my team.
                    </Body>

                </Container>
            </FadeIn>

            <FadeIn>
                <Container>

                    <SubTitle>
                        Software Engineering Career Experience
                    </SubTitle>

                    <SubText>
                        Jun 2021 - Nov 2021
                    </SubText>

                    <Body>
                        I had the opportunity to work as a software engineer on a team that ensures that APIs that most Apple devices communicate with perform as expected. This experience differed from an internship
                        as I was considered an actual engineer on the team. Career experiences are competitive initiatives that allow retail employees of Apple undergo a 5-month rotation as corporate employees.
                    </Body>

                    <Body>
                        During my experience, I worked with the Device Services QA team and I learned a lot. My work entailed the following: <br /> <br />
                        • I converted and extended existing test suites written in Java to a lighter-weight framework <Link target="_blank" rel="noreferrer" href="https://github.com/karatelabs/karate"> Karate </Link>
                        which saw a reduction in build and test times by a factor of 4. <br />
                        • I identified and implemented new test cases, increasing test coverages by over 40%. <br />
                        • I laid the groundwork for implementing future tests with Karate by documenting test cases and presenting my work to the entire team.
                    </Body>

                </Container>

            </FadeIn>

            <FadeIn>
                <Container>

                    <Flicker>
                        <Title>
                            University of Maryland, iSchool
                        </Title>
                    </Flicker>

                    <SubTitle>
                        Software Engineer / Research Assistant
                    </SubTitle>

                    <SubText>
                        Dec 2019 - May 2021
                    </SubText>

                    <Body>
                        During the last three semesters of my undergraduate career at the University of Maryland, I was a member of the Intelligent Assistive Machines (IAM) lab. During that time, I collaborated with
                        <Link target="_blank" rel="noreferrer" href="https://jonggi.github.io"> Jonggi Hong </Link> and <Link target="_blank" rel="noreferrer" href="https://terpconnect.umd.edu/~hernisa/"> Hernisa Kacorri </Link> to 
                        further work on a teachable object recognizer <Link target="_blank" rel="noreferrer" href="https://jonggi.github.io/projects/index.html#teachable-interface-for-personalizing-an-object-recognizer"> iOS app </Link>
                        that leveraged ML models to assist blind users identify objects. I also co-authored papers that were presented at ACM ASSETS and CHI conferences.
                    </Body>

                    <Body>
                        My experience at the lab taught me the importance of collaboration, seeking and giving constructive feedback and employing an iterative approach to software development. I also learned the 
                        importance of designing and implementing software that is accessible for all.
                    </Body>
                </Container>

                <Spacer/>
                
            </FadeIn>

        </Section>
    )
};

export default Experience;