import {FaArrowCircleUp} from 'react-icons/fa';
import styled from "styled-components";
import { ToolTip } from "../components/Section";

const Text = styled(ToolTip)`
  opacity: 0;
  position: relative;
  top: 0;
  transition: all ease-out 0.3s;
;`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100px;
  //justify-content: center;
  //align-items: flex-start;
  
  //background-color: red;
  @media only Screen and (max-width: 40em) {
    display: none;
  }

  // @media only screen and (min-width: 390px) and (orientation: landscape) { 
  //   background-color: red;

  // }
`;

const Button = styled.button`
   
  //margin-right: 3rem;
  //margin-bottom: 3rem;
  width: 48px;
  height: auto;
  font-size: 3rem;
  cursor: pointer;
  color: var(--green);
  background-color: transparent;
  align-self: center;

  position: relative;
  top: 0;
  transition: top ease-out 0.3s;

  

  &:hover {
    top: -10px;

    &:hover + ${Text} {
      opacity: 1;
      top: -10px;
    }
  }
`;


const ScrollButton = () =>{
  
  const scrollToTop = () =>{
    const parent = (document.getElementsByClassName("parent"))[0];
    scrollTo(parent);
  };

  // Taken from: https://gist.github.com/andjosh/6764939
  function scrollTo(element, to = 0, duration= 250) {

    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = (() => {

      currentTime += increment;

      const val = Math.easeInOutQuad(currentTime, start, change, duration);

      element.scrollTop = val;

      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    });

    animateScroll();
  };

  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
  };
  
  
  return (
    <ButtonContainer>
      <Button className="myDIV">
        <FaArrowCircleUp onClick={(scrollToTop)} />
      </Button>

      <Text>
        Back to top
      </Text>
      
    </ButtonContainer>
    
  );
}
  
export default ScrollButton;